/*
 * Custom components
 */

//Note: The Effra webfont is purchased by LSN (Anita Nouwens) from https://www.daltonmaag.com/library/effra

@font-face {
  font-family: 'effra';
  src: url('../fonts/effra/Effra_W_Rg.eot');
  src: url('../fonts/effra/Effra_W_Rg.eot?#iefix') format('embedded-opentype'),
  url('../fonts/effra/Effra_W_Rg.woff2') format('woff2'),
  url('../fonts/effra/Effra_W_Rg.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}


@font-face {
  font-family: 'effra';
  src: url('../fonts/effra/Effra_W_Bd.eot');
  src: url('../fonts/effra/Effra_W_Bd.eot?#iefix') format('embedded-opentype'),
  url('../fonts/effra/Effra_W_Bd.woff2') format('woff2'),
  url('../fonts/effra/Effra_W_Bd.woff') format('woff');
  font-style: normal;
  font-weight: bold;
}


//add subtle transition on elements that have a hovered or active state
a, .btn, .form-control {
  transition: color 0.2s ease-in-out 0s,
    background 0.2s ease-in-out 0s,
    border 0.2s ease-in-out 0s,
    text-decoration 0.2s ease-in-out 0s,
    box-shadow 0.2s ease-in-out 0s
  ;
}

.link-more {
  &:after {
    @include fa-icon();
    display: inline;
    content: $fa-var-angle-right;
    padding-left:5px;
    top:1px;
    position: relative;
  }
}

h1, h2, h3, h4 {
    margin-bottom: 10px;
    margin-top: 15px;
}
h5, h6 {
    margin-bottom: 0px;
    margin-top: 10px;
}
ul + h2,
p + h2 {
    margin-top: 40px;
}

h1::after,
h2::after {
    background: $brand-secondary none repeat scroll 0 0;
    border-radius: 50%;
    content: " ";
    display: inline-block;
    height: 10px;
    margin-left: 7px;
    padding: 2px;
    width: 10px;
}