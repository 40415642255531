@font-face {
  font-family: 'icomoon';
  src: url('/global/fonts/icomoon/icomoon.eot?s4zyfs');
  src: url('/global/fonts/icomoon/icomoon.eot?s4zyfs#iefix') format('embedded-opentype'),
    url('/global/fonts/icomoon/icomoon.ttf?s4zyfs') format('truetype'), url('/global/fonts/icomoon/icomoon.woff?s4zyfs') format('woff'),
    url('/global/fonts/icomoon/icomoon.svg?s4zyfs#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-limiet_wijzigen:before {
  content: '\e91d';
}

.icon-visa-secure:before {
  content: '\e999';
}
.icon-verified_by_visa:before {
  content: '\e912';
}
.icon-visa_logo:before {
  content: '\e91c';
}
.icon-persoonlijke_lening:before {
  content: '\e924';
}
.icon-arbeidsvoorwaarden:before {
  content: '\e917';
}
.icon-mijn_qander:before {
  content: '\e91a';
}
.icon-opleiding:before {
  content: '\e918';
}
.icon-open_sollicitatie:before {
  content: '\e919';
}
.icon-zoeken:before {
  content: '\e916';
}
.icon-home:before {
  content: '\e915';
}
.icon-geld_opnemen:before {
  content: '\e907';
}
.icon-punten:before {
  content: '\e900';
}
.icon-adres:before {
  content: '\e901';
}
.icon-bankrekening:before {
  content: '\e902';
}
.icon-bedrijf:before {
  content: '\e903';
}
.icon-betalen:before {
  content: '\e904';
}
.icon-e-mailadres:before {
  content: '\e905';
}
.icon-extra_zekerheid:before {
  content: '\e906';
}
.icon-geschiedenis:before {
  content: '\e908';
}
.icon-juridische_helpdesk:before {
  content: '\e909';
}
.icon-kredietlimiet_verhogen:before {
  content: '\e90a';
}
.icon-partnerkaart:before {
  content: '\e90b';
}
.icon-persoonlijke_gegevens:before {
  content: '\e90c';
}
.icon-pincode:before {
  content: '\e90d';
}
.icon-pinnen:before {
  content: '\e90e';
}
.icon-telefoon:before {
  content: '\e90f';
}
.icon-u_en_wij:before {
  content: '\e910';
}
.icon-vacatures:before {
  content: '\e911';
}
.icon-vraag_en_antwoord:before {
  content: '\e913';
}
.icon-wachtwoord:before {
  content: '\e914';
}
