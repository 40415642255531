// Sections
// -------------------------
.section-green {
    background: $brand-primary;
    padding: 30px 0 60px;

    h1, h2, h3, h4, h5, h6, p {
        color: #fff;
    }

    .btn-primary {
        background: #fff;
        border-color: #fff;
        color: $brand-primary;

        &:after {
            background: $brand-primary;
        }
    }
}

.section-white {
    background: #fff;
    padding: 30px 0;
}

.section-orange {
    background: $brand-secondary;
    padding: 30px 0;

    h1, h2, h3, h4, h5, h6, p {
        color: #fff;
        &:after {
            background-color: $brand-primary;
        }
    }

    .btn-primary {
        background: #fff;
        border-color: #fff;
        color: $brand-primary;

        &:after {
            background: $brand-primary;
        }
    }
}

.section-gray {
    padding: 30px 0;
    background: #f2f2f2;
}

.section-centered {
    text-align: center;
}

.section-hulp-nodig {
    padding: 10px 0 15px;
    @include isaac-respond-min-width(sm) {
        padding: 20px 0 30px;
    }
    h3 {
        color: $brand-secondary;
        font-size: 35px;
    }
    .row {
        margin-top: 30px;
        p {
            margin: 0;
        }
        a,
        span {
            font-size: 30px;
            color: $brand-primary;
            font-weight: bold;
            i {
                color: $brand-secondary;
            }
        }
    }
}

.section-jumbotron-banner {
    padding: 20px 0 25px 0;
    @include isaac-respond-min-width(sm) {
        padding: 30px 0;
    }
    @include isaac-respond-min-width(lg) {
        padding: 40px 0 30px 0;
    }
    h2 {
        margin: 0 0 10px 0;
        padding-left: 38px;
        position: relative;
        font-size: 32px;

        @include isaac-respond-min-width(md) {
            margin: 0;
            padding-top: 0;
            font-size: 36px;
            padding-left: 80px;
        }

        i {
            left: 0;
            top: 0;
            position: absolute;
            @include isaac-respond-min-width(md) {
                font-size: 80px;
            }
        }
    }
    .btn {
        padding: 15px 0;
        font-size: 18px;
        margin: 0;
        @include isaac-respond-min-width(lg) {
            padding: 20px 0;
            font-size: 25px;
        }

        &:after {
            height: 12px;
            width: 12px;
        }
    }
}

.section-vacatures {
    .item-vacature {
        border-top: 1px solid #ebebeb;
        .btn {
            display: block;
            padding: 15px 0;
            overflow: hidden;

            .vacature-name {
                text-align: left;
                text-decoration: underline;
                font-weight: bold;
            }

            .vacature-date {
                text-align: left;
                color: #999;
                font-size: 16px;

                @include isaac-respond-min-width(sm) {
                    text-align: right;
                }
            }
        }
        &:first-child {
            border: medium none;
        }
    }
}

.section-solliciteer-CTA {
    padding: 10px 0 15px;
    @include isaac-respond-min-width(sm) {
        padding: 15px 0 30px;
    }

    h3 {
        color: $brand-secondary;
        font-size: 35px;
    }
    .section-solliciteer-top {
        margin-bottom: 20px;
        @include isaac-respond-min-width(sm) {
            margin-bottom: 40px;
        }
        .btn {
            color: #fff;
            padding: 15px 30px;
            font-size: 25px;
        }
    }

    .section-solliciteer-bottom {
        a,
        span {
            font-size: 30px;
            color: $brand-primary;
            font-weight: bold;
            i {
                color: $brand-secondary;
            }
        }
    }
}

.section-social {
    i {
        font-size: 30px;
        color: $brand-secondary;
        color: $gray;
    }
}

.section-faq {
    ul.list-group-links {
        margin: 0;
        li {
            margin: 0;
            border-bottom: 1px solid #ebebeb;
            &:first-child {
                border-top: 1px solid #ebebeb;
            }
            a {
                padding: 10px 0 10px 35px;
                &:before {
                    left: 20px;
                }
            }
        }
    }
}

.section-verantwoorde-financiele-ruimte {
    img {
        margin: 10px auto 20px;
    }
    .img-flying {
        display: none;
        @include isaac-respond-min-width(sm) {
            display: block;
            margin-bottom: 40px;
            bottom: -30px;
            margin: 0;
            position: absolute;
            right: 0;
        }
    }
}

.section-search {
    .keyword {
        font-weight: bold;
        color: $brand-primary;
    }
}

.section-contact {
    h2 {
        font-size: 26px;
    }
    h3 {
        font-size: 22px;
    }
    h4 {
        font-size: 12px;
    }
    h5 {
        font-size: 12px;
    }
}

.section-investor-list {

    & .pills-container {
        padding-left:0;
    }

    .list-group-item {

        @include isaac-respond-max-width(sm) {
            text-align: center;
        }

        .item-date {
            text-align: center;
            color: $brand-secondary;
            line-height:1;


            .item-date-day {
                padding: 0;
                margin: 0;
                display:block;
                font-size: 40px;
                font-weight: bold;
            }
            .item-date-month {
                display:block;
                font-size: 20px;
                font-weight: bold;
            }
            .item-date-year {
                display:block;
                margin-top:5px;
            }

            @include isaac-respond-max-width(xs) {
                text-align: center;
                & h1, & h5, & h5 span {
                    display: inline;
                }
            }

        }

        .item-title {
            margin-top:12px;
        }

        .item-download {
            margin-top:8px;

            & a::before {
                font-family: FontAwesome;
                content: "\f016";
                padding-right: 5px;
            }

            & a[href$=".pdf"]::before {
                content: "\f1c1";
            }

            & a[href$=".xls"]::before, & a[href$=".xlsx"]::before{
                content: "\f1c3";
            }

            & a[href$=".ppt"]::before, & a[href$=".pptx"]::before{
                content: "\f1c4";
            }

            & a[href$=".doc"]::before, & a[href$=".docx"]::before{
                content: "\f1c2";
            }

        }

    }
    &:first-child {
        border: medium none;
    }
}

#investor-error-login {
    display: none;
    margin:10px 0;
    padding:15px;
}


// Wrapper
// --------------------------
.col-wrapper {
    padding: 0 20px 20px;
    margin: 0 0 20px;
}

.col-wrapper-transparent {

}

.col-wrapper-white {
    background: #fff;
}

.col-wrapper-green {
    background: $brand-primary;
    color: #fff;
    p {
        color: #fff;
    }

    h1, h2, h3, h4, h5 {
        color: #fff;
    }

    .btn-primary {
        background: #fff;
        border-color: #fff;
        color: $brand-primary;
        &:after {
            background: $brand-primary;
        }
    }

}

.col-wrapper-orange {
    background: $brand-secondary;
}

.col-wrapper-centered {
    text-align: center;
}