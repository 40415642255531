/*.panel-group {*/

.panel {
    box-shadow: none;
    border:medium none;
    .panel-heading {
        background:transparent;
        padding:0 20px;
        overflow: hidden;
        &.investor-login {
            border-bottom: 1px solid $table-border-color;
        }

        h3 {
            color: $brand-primary;
            font-size: 26px;
        }
    }
    .panel-body {
        padding:15px 0 20px 0;
    }
    .panel-footer {
        border:none;
        padding:0;
        background:transparent;
        overflow:hidden;
    }
}

.panel-group-collapsible {
    /*add carets and maximize click area*/
	.panel-heading {
		padding: 0; //remove default padding
        
        a {
            color:$gray;
            font-size:18px;
            font-weight:normal;
        }
	
		a[data-toggle="collapse"] {
			position:relative;
			display:block;
			padding: 15px 15px 15px 37px; //maximize click area, left padding also creates space for caret
			text-decoration:none;
			&:before {
				@include caret($caret-width-base, $brand-secondary , bottom);
				left:20px;
				top:22px;
			}
	
			&.collapsed {
				&:before {
					@include caret($caret-width-base, $brand-secondary , right);
					margin-left:$caret-width-base / 2;
					margin-top:- $caret-width-base / 2;
				}
			}
		}
    }
    .panel-body {
        padding:0 20px 20px 20px;
    }
}