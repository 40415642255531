// Add extra classes for creating well-formatted content within `.list-group-item`s.
.list-group-item-thumbnail {
  width:100%;
}

.list-group-item {
    margin-bottom:6px;
}

.list-group {
    /* Search result pages*/
    padding-left:0px;
    
    a.list-group-item {
        padding:25px 0;
        border-bottom:1px solid #ebebeb;
        border-top:0;
        margin:0;
        
        &:first-child {
            border-top:1px solid #ebebeb;
        }
        
        .list-group-item-heading {
            color:$brand-primary;
            font-size:22px;
            
            &:after {
                background: #ffa300 none repeat scroll 0 0;
                border-radius: 50%;
                content: " ";
                display: inline-block;
                height: 7px;
                margin-left: 7px;
                padding: 2px;
                width: 7px;
            }
        }
        
        &:hover {
            background:transparent;
            
            .list-group-item-heading {
                color: darken($brand-primary, 10%);
                &:after {
                    background-color: darken($brand-secondary, 5%);
                }
            }
            
            .list-group-item-text {
                color:#333;
            }
        }
    }
}

.list-group-check {
    list-style: none;
    padding-left:0;
    
    li {
        line-height: 22px;
        margin-bottom: 15px;
        text-align: left;
        /*margin-bottom: 25px;*/
        
        color:$gray;
        display: block;
        padding-left: 23px;
        position: relative;

        &:before {
            font-family: FontAwesome;
            content: " ";
            color:$brand-secondary;
            position:absolute;
            left:0px;
        }
    }
}

.list-group-links {
    list-style: none;
    padding-left:0;
    
    li {
        line-height: 22px;
        margin-bottom: 10px;
        a {
            color:$gray;
            display: block;
            padding-left: 15px;
            position: relative;
            
            &:before {
                font-family: FontAwesome;
                content: " ";
                color:$brand-secondary;
                position:absolute;
                left:0px;
            }
            
            &:hover {
                text-decoration: none;
                color:$brand-primary;
            }
        }
    }
}

.col-wrapper-orange .list-group-links,
.col-wrapper-green .list-group-links {
    li a {
        color:#fff;
        &:before {
            color:#fff;
        }
    }
}