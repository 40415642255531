//nav-bar
.nav > li > a {
    height:46px;
    padding: 10px 15px;
}
.navbar {
    background:$brand-primary;
    li {
        a {
            color:#fff;
            &:hover {
                background: darken($brand-primary, 10%);
            }
            &:focus {
                background-color:$brand-primary;
            }
        }

        &.active > a {
            background: $brand-secondary;
            
            &:hover {
                
                background: darken($brand-secondary,3%);
            }
        }

        &.dropdown.open > a {
            background: darken($brand-primary, 10%);
        }
        
        /* Home knop in navigatie vervangen voor icoon */
        &:first-child {
            a {
                display: block;
                height: 0;
                overflow: hidden;
                padding: 46px 0 0;
                width: 40px;
                
                &:after {
                    color: #fff;
                    content: "";
                    font-family: "icomoon" !important;
                    font-size: 38px;
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    left: 1px;
                    line-height: 1;
                    position: absolute;
                    text-transform: none;
                    top: 3px;
                }
            }
        }
    }
}

//Aside
.section-nav-sub {
    border-bottom: 1px solid #cccccc;
    padding: 20px 0 10px;
    margin-bottom:-1px;
    @include isaac-respond-min-width(sm){
        padding: 36px 0 30px;
        overflow:hidden;
    }
}
.nav-sub {
    margin: 0 -6px;
    display:block;
    
    @include isaac-respond-min-width(xs){
        display: inline-block;
        width:100%;
    }
    @include isaac-respond-min-width(lg){
        width:auto;
    }
    li {
        background:#fff;
        display:block;
        width:100%;
        margin: 0 0 7px;
        
        @include isaac-respond-min-width(xs){
            width: calc(33% - 12px);
            margin:0 6px 12px 6px;
            float:left;
            display:table;
        }
        @include isaac-respond-min-width(lg){
            width:170px;
            margin:0 10px 0;
        }
        
        a {
            font-size:16px;
            font-weight:bold;
            width:100%;
            text-align: left;
            line-height: 1;
            color: $brand-primary;
            padding: 15px 10px 15px 50px;
            white-space: normal;
            border:2px solid #fff;
            
            @include isaac-respond-min-width(xs){
                display: table-cell;
                height:127px;
                text-align: center;
                padding: 75px 5px 0;
                position: relative;
                vertical-align: middle;
            }
            
            @include isaac-respond-min-width(md){
                padding: 75px 15px 0;
            }
            
            i {
                font-size: 40px;
                height: auto;
                left: 0;
                position: absolute;
                top: 3px;
                width: 40px;
                color:$brand-secondary;
                
                @include isaac-respond-min-width(xs){
                    font-size: 70px;
                    width:100%;
                    top:10px;
                }
            }
            &:focus {
                color:$brand-primary;
                background-color:#fff;
                border:1px solid #ddd;
                i {
                    color:$brand-secondary;
                }
            }
            &:hover {
                color:$brand-primary;
                background-color:#fff;
                i {
                    color:$brand-secondary;
                    
                }
                border:2px solid $brand-secondary;
            }
        }
        
        &.active {
            a {
                background-color:$brand-secondary;
                border:2px solid $brand-secondary;
                
                color:#fff;
                cursor: default;
                i {
                    color:#fff;
                }
            }
        }
    }
    
    
}
