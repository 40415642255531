.kiyoh-widget {
  width: 500px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d4e4ec;
  padding: 2px 16px;
  margin-top: 20px;
  a {
    &:hover {
      text-decoration: none;
    }
  }
  span.number {
    background-color: $brand-primary;
    width: 54px;
    height: 54px;
    display: flex;
    padding-top: 2px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    font-size: $font-size-base;
    font-weight: 700;
    margin-right: 12px;
  }
  .carousel {
    width: 100%;
    .item {
      width: 100%;
      a {
        font-size: $font-size-small;
        color: $gray-light;
        @include isaac-respond-width(xs) {
          font-size: 12px;
        }
      }
      img {
        margin-right: 10px;
      }
    }
  }
}
.rating {
  position: relative;
  display: block;
  color: $gray-dark;
  margin-top: 3px;

  span {
    display: none;
  }

  &:after,
  &:before {
    font-family: FontAwesome;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;
    font-size: 20px;

    @include isaac-respond-min-width(sm) {
      font-size: 20px;
    }

    @include isaac-respond-min-width(md) {
      font-size: 1.4em;
    }
  }

  //5 background color stars
  &:before {
    display: inline-block;
    //note: \00a0 = non breaking space,  \f005 - star (icon)
    content: "\f005\00a0\f005\00a0\f005\00a0\f005\00a0\f005";
    color: $gray-lighter;
  }

  &:after {
    display: inline-block;
    position: absolute;
    color: $brand-secondary;
    left: 0;
    top: 0;
  }

  //make sure to add at least one &nbsp; to get perfect alignment for IE11
  &.rating-1:after {
    content: "\f005\00a0";
  }

  &.rating-1-5:after {
    content: "\f005\00a0\f089";
  }

  &.rating-2:after {
    content: "\f005\00a0\f005";
  }

  &.rating-2-5:after {
    content: "\f005\00a0\f005\00a0\f089";
  }

  &.rating-3:after {
    content: "\f005\00a0\f005\00a0\f005";
  }

  &.rating-3-5:after {
    content: "\f005\00a0\f005\00a0\f005\00a0\f089";
  }

  &.rating-4:after {
    content: "\f005\00a0\f005\00a0\f005\00a0\f005";
  }

  &.rating-4-5:after {
    content: "\f005\00a0\f005\00a0\f005\00a0\f005\00a0\f089";
  }

  &.rating-5:after {
    content: "\f005\00a0\f005\00a0\f005\00a0\f005\00a0\f005";
  }
}

.overall-rating-widget {
  display: inline-block;
  position: relative;
  margin-top: 20px;
  img {
    position: absolute;
    top: 35px;
    right: -25px;
    max-width: 80px;
  }
  .rating {
    top: 10px;
  }
  .number {
    display: block;
    font-size: 80px;
    font-weight: 600;
    color: $brand-primary;

    small {
      color: $gray;
      font-size: 40px;
    }
  }
  p {
    small {
      color: $gray;
    }
  }
}

.list-ratings {
  list-style: none;
  padding-top: 20px;
  > li {
    padding: 20px 0 10px;
    border-top: 1px dashed #d4e4ec;
    &:before {
      display: none;
    }

    .rating-metadata {
      color: $gray-light;
      margin: 9px 0 8px;
    }

    .rating-pluses {
      label {
        font-weight: bold;
      }
    }
  }
}

.large-banner {
  .kiyoh-widget {
    position: absolute;
    right: 145px;
    
  }
}

.small-banner {
  .kiyoh-widget {
    position:unset;
    width: auto;
  }
}
