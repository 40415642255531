// website without offcanvas menu is contained within #content
#content {
  background-color: $content-bg;
    &.investor-login {
        height: 100vh;
        background: #fff url(/global/css/img/bg.svg) bottom right no-repeat;
        background-size: 640px 480px;
        background-attachment: local;
    }
}

main {
  padding:0;
    &.investor-login {
        padding-top:46px;
        background: rgba(236,236,236,0.95);
    }
}


