header {
  background-color: $brand-primary;

  @include isaac-respond-min-width(sm) {
    background-color: #fff;
    margin-bottom: 46px;

    &.investor-login {
      margin-bottom: 0;
    }
  }

  .afm {
    background: #fff url(img/afm.svg) no-repeat center center;
    background-size: 380px;
    text-indent: -99999px;
    height: 100px;
    border-bottom: 1px solid #000;
    @include isaac-respond-width(xs) {
      background-size: 260px;
      height: 70px;
    }
  }

  .header-content {
    height: 50px;
    padding: 0;
    position: relative;

    body.offcanvas-open & {
      border-left: 1px solid #fff;
    }

    @include isaac-respond-min-width(sm) {
      height: 80px;
      padding-top: 18px;
    }

    #toggle-offcanvas {
      display: inline-block;
      height: 50px;
      cursor: pointer;

      .btn {
        border: medium none;
        color: #fff;
        height: 50px;
        padding: 13px;
        background: #fff;

        .closed {
          display: none;
          color: #fff;
        }
        .opened {
          color: $brand-primary;
        }
        &.collapsed {
          background: $brand-primary;
          color: #fff;
          .closed {
            display: block;
          }
          .opened {
            display: none;
          }
        }
      }
    }

    .logo {
      a {
        background: transparent url('/global/css/img/logo-qander-white.svg')
          no-repeat scroll 0 0;
        height: 0;
        left: 50%;
        margin: 5px 0 0 -74px;
        overflow: hidden;
        padding-top: 41px;
        position: absolute;
        top: 0;
        width: 165px;
        display: block;
        text-indent: -10000px;

        @include isaac-respond-min-width(sm) {
          position: relative;
          background: rgba(0, 0, 0, 0) url('/global/css/img/logo-qander.svg')
            no-repeat scroll 0 0;
          background-size: contain;
          left: auto;
          margin: 0;
          margin-top: -10px;
          width: 175px;
          height: 72px;
        }
      }
    }
    .logo-aurorus {
      a {
        background: url(/global/css/img/logo-aurorus-white.svg) no-repeat scroll
          0 0;
        height: 0;
        left: 50%;
        margin: 2px 0 0 -55px;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 129px;
        height: 100px;
        display: block;
        background-size: contain;
        text-indent: -10000px;

        @include isaac-respond-min-width(sm) {
          position: relative;
          background: url('/global/css/img/logo-aurorus.svg') no-repeat scroll 0
            0;
          background-size: contain;
          left: auto;
          margin: 0;
          margin-top: -10px;
          width: 175px;
          height: 72px;
        }
      }
    }
    // corporate customer switch
    .corporate-customer-toggle {
      margin: 10px 0 0 20px;
      a {
        color: #666;
        display: inline;
        float: left;
        padding: 0 10px;
        &.active {
          color: $brand-primary;
          font-weight: bold;
          background: transparent;
          border-color: transparent;
        }
      }
      .pipe {
        background: #000 none repeat scroll 0 0;
        display: inline;
        float: left;
        height: 22px;
        margin: 3px 5px;
        width: 1px;
      }
    }

    .search-sm {
      .btn {
        color: #fff;
        height: 50px;
        width: 50px;
        background: #fff;
        padding: 11px 0 0;

        .search-toggle-close {
          color: $brand-primary;
        }
        .search-toggle-open {
          display: none;
        }

        &.collapsed {
          background: transparent;

          .search-toggle-close {
            display: none;
          }

          .search-toggle-open {
            display: inline;
          }
        }
      }
    }

    .search-md {
      margin: 5px 10px 0 0;
      width: 150px;

      @include isaac-respond-min-width(md) {
        width: 200px;
      }

      .form-group {
        margin: 0;
        display: block;
      }
      button {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 4;
        background: transparent;
        box-shadow: 0 0 0 transparent;
        border: medium none;
      }
      input {
        padding-right: 35px;
        border-radius: 0;
      }
    }

    .btn-mijn-qander,
    .btn-investors {
      position: relative;
      > a {
        margin-top: 5px;
        background-color: #ececec;
        color: #666666;
        padding: 6px 13px 6px 15px;

        @include isaac-respond-min-width(md) {
          &:after {
            background-color: #666666;
            border-radius: 4px;
            content: ' ';
            display: inline-block;
            height: 8px;
            margin-left: 10px;
            position: relative;
            top: -1px;
            width: 8px;
          }
        }

        .fa {
          margin-right: 10px;
        }
      }
      &.open {
        > a {
          background-color: #666;
          color: #fff;

          &:after {
            background-color: #fff;
          }
        }
      }

      .dropdown-menu-mijn-qander {
        background: #ececec none repeat scroll 0 0;
        border: medium none;
        float: none;
        margin-top: 5px;
        top: auto;
        padding: 0;

        > li > a {
          line-height: 13px;
          padding: 15px 15px 15px 30px;

          &:before {
            content: ' ';
            font-family: FontAwesome;
            left: 15px;
            position: absolute;
          }
          &:hover {
            background: #ddd;
          }
        }
      }
    }

    .btn-investors {
      margin-left: 10px;
    }
  }

  #header-sticky {
    width: 100%;
    z-index: 999;
    &.affix-top {
      position: absolute;
    }

    &.affix {
      position: fixed;
      top: 0;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    }
  }

  // No error labels allowed in the header
  label.has-error {
    display: none !important; //prevents inline style overruling.
    border: 1px solid #f00;
  }
}
