.equal-height {
    overflow:hidden;
    
    @include isaac-respond-min-width(sm){
        .equal-height-outer-row {
            display:table;
            border-spacing: $grid-gutter-width 0;
            margin:0 -30px $grid-gutter-width;
            
            .equal-height-inner-row {
                display: table-row;
                > [class^="col-"], [class*=" col-"] {
                    display: table-cell;
                    float:none;
                }
            }
        }
    }
    
    .equal-height-body {
        //create space for the footer
        padding-bottom:55px;
        overflow:hidden;
    }
    
    .equal-height-footer {
        bottom: 0;
        position: absolute;
        width: 100%;
        left:0;
    }
}