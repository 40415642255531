//
// Buttons
// --------------------------------------------------

.btn {
    &.active {
        box-shadow: 0 0 0 transparent;
        background:$brand-secondary;
        border-color:$brand-secondary;
        color:#fff;
    }
}

.btn-primary {
    font-weight:bold;

    &:after {
        background-color: #fff;
        border-radius: 50%;
        content: " ";
        display: inline-block;
        height: 8px;
        margin-left: 10px;
        position: relative;
        top: -1px;
        width: 8px;
    }
}

.btn-right {
    float: right;
    margin-bottom: 20px;
}

.btn-secondary {
    background:$brand-secondary;
    border-color:#fff;
    color:#FFF;
    &:after {
        background:$brand-primary;
    }
}

.btn-inverted {
    background:#fff;
    border-color:#fff;
    color:$brand-primary;
    &:after {
        background:$brand-primary;
    }
}
