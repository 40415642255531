.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -45px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 12px;
        width: 12px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            display: block;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            background-color: $brand-secondary;
            padding: 5px;
            cursor: pointer;
            opacity: 0.3;
            &:hover, &:focus {
                outline: none;
            }

        }
        &.slick-active button {
            opacity:1;
        }
    }
}


.slider-partners  {
    .slider {
        display:none;
        &.slick-initialized {
            display:block;
        }
        .slick-slide  {
            padding:10px;
        }
        .slick-arrow {
            display:none!important;
        }
    }
}