//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {
    width:100%;
    text-align: center;
    li {
        
        a {
            border:medium none;
            float:none;
            
        }
        
        &.disabled {
            a {
                color:#ccc;
            }
        }
        
        &.active {
            a {
                z-index: auto;
            }
        }
    }
}