//default bootstrap Jumbotron css is not used, but the DOM is.
.jumbotron {
  background-color: #3fae28;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 140px;
  min-height: 140px;
  position: relative;
  overflow: hidden;
  background-size: cover;

  @include isaac-respond-min-width(xs) {
    min-height: 200px;
  }

  @include isaac-respond-min-width(sm) {
    min-height: 200px;
  }

  @include isaac-respond-min-width(md) {
    height: 550px;
  }
  .jumbotron-circle {
    display: none;
    @include isaac-respond-min-width(xs) {
      background: transparent no-repeat;
      background-image: url('/global/css/img/jumbotron-circle.png');
      background-size: 467px auto;
      background-position: 0 0;

      bottom: 0;
      display: block;
      height: 100%;
      position: absolute;
      right: 0;
      width: 295px;
    }
    @include isaac-respond-min-width(sm) {
    }
    @include isaac-respond-min-width(md) {
      background-position: 0 0;
      background-size: 1086px auto;
      width: 670px;
    }
    @include isaac-respond-min-width(lg) {
      background-size: 1086px auto;
    }
  }
  .jumbotron-body {
    height: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    display: table;
    padding-top: 80px;

    @include isaac-respond-width(xs) {
      padding-top: 38px;
    }

    .jumbotron-heading {
      color: #fff;
      text-align: right;
      margin: 0;
      display: block;
      font-size: 28px;
      padding: 0 15px;

      @include isaac-respond-min-width(xs) {
        padding: 0 30px;
        font-size: 40px;
      }
      @include isaac-respond-min-width(sm) {
        font-size: 45px;
        line-height: 45px;
        padding: 0 60px 0 30%;
      }
      @include isaac-respond-min-width(md) {
        padding: 0 150px;
        font-size: 72px;
        line-height: 80px;
      }
      @include isaac-respond-min-width(lg) {
        padding: 0 150px 0 30%;
      }

      &:after {
        background: none;
        content: none;
      }
    }
    .jumbotron-footer {
      max-width: 300px;
    }
  }

  .green {
    position: absolute;
    background: #3fae28;
    top: 90px;
    right: 20px;
    padding: 40px 20px;
    width: 40vw;
    text-align: right;
    height: auto;
    h1,
    h3 {
      color: #fff;
    }
    h1 {
      font-size: 55px;
      margin-top: 0;
    }
    h3 {
      font-size: 40px;
      margin-bottom: 0;
    }
    @include isaac-respond-max-width(sm) {
      top: 5px;
      right: 0;
      width: 46vw;
      padding: 20px;
      text-align: center;
      h1,
      h3 {
        font-size: 30px;
      }
    }
    @include isaac-respond-width(xs) {
      top: 5px;
      right: 0;
      width: 100vw;
      padding: 5px 10px;
      text-align: center;
      h1,
      h3 {
        font-size: 25px;
      }
    }
  }
  .orange {
    position: absolute;
    background: $brand-secondary;
    bottom: 0;
    width: 100vw;
    padding: 20px;
    text-align: center;
    span {
      color: #fff;
      font-size: 30px;
      font-weight: bold;
      position: relative;
      &:after {
        background: $brand-primary none repeat scroll 0 0;
        border-radius: 50%;
        content: ' ';
        display: inline-block;
        height: 10px;
        margin-left: 7px;
        padding: 2px;
        width: 10px;
      }
      i {
        font-size: 60px;
        position: absolute;
        top: -12px;
        left: -60px;
        @include isaac-respond-width(xs) {
          font-size: 48px;
          left: -45px;
        }
      }
      a {
        color: #fff;
        text-decoration: underline;
      }
      a:hover {
        color: #fff;
        text-decoration: none;
      }
      @include isaac-respond-max-width(sm) {
        font-size: 30px;
      }
      @include isaac-respond-width(xs) {
        font-size: 20px;
      }
    }
    @include isaac-respond-max-width(sm) {
      padding: 10px;
    }
  }
}

.jumbotron-middle {
  min-height: 170px;
  @include isaac-respond-min-width(xs) {
    min-height: 200px;
  }
  @include isaac-respond-min-width(sm) {
    min-height: 200px;
  }
  @include isaac-respond-min-width(md) {
    height: 400px;
  }

  .jumbotron-circle {
    @include isaac-respond-min-width(xs) {
      background-position: -290px 2px;
      right: auto;
      left: 0;
      width: 765px;
    }
    @include isaac-respond-min-width(sm) {
      background-position: -290px 2px;
      right: auto;
      left: 0;
      width: 765px;
    }
    @include isaac-respond-min-width(md) {
      background-position: -590px -32px;
      width: 765px;
    }
  }
  .jumbotron-body {
    right: auto;
    left: 0;
    height: 100%;
    padding: 20px 0 0 20px;
    display: block;

    .btn {
      margin-top: 15px;
    }

    @include isaac-respond-min-width(xs) {
      padding: 25px 0 0 25px;
      .btn {
        margin-top: 10px;
      }
    }
    @include isaac-respond-min-width(sm) {
      padding: 30px 0 0 30px;
      .btn {
        margin-top: 10px;
      }
    }
    @include isaac-respond-min-width(md) {
      padding: 100px 0 0 100px;
      .btn {
        margin-top: 30px;
      }
    }
    @include isaac-respond-min-width(lg) {
      padding: 100px 0 0 150px;
      .btn {
        margin-top: 30px;
      }
    }

    .jumbotron-heading {
      text-align: left;
      padding: 0;
      @include isaac-respond-min-width(md) {
        top: 80px;
      }
    }
  }
}

.jumbotron-vraag-en-antwoord {
  @include isaac-respond-min-width(md) {
    padding: 100px 0 0 0;
    height: 450px;
  }

  .col-vraag-en-antwoord {
    background-color: $brand-primary;

    h2 {
      border-bottom: 1px solid #fff;
      padding-bottom: 20px;
    }
    ul {
      margin: 10px 0 -10px 0;
      li {
        font-size: 22px;
        line-height: 35px;
      }
    }
  }
}
