// do not use the max-width but use the width because on Samsung Android (Galaxy S3 e.g.) the popover inner content
// does not honor the width which causes the text to fall out of the popover container itself.
.popover {
    max-width: inherit !important;
    width: $popover-max-width;
}

// use extra margin to center the content, because we use a fixed width.
.popover-content {
    margin: 0 auto;
}