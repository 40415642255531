// Offcanvas menu
#mobile-menu {
    .panel {
        margin:0;
    }
}


#offcanvas-menu {
    background: #fff none repeat scroll 0 0;
    box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
    position: absolute;
    width: 100%;
    z-index: 2;
    
    &.collapse {
        display:block;
        max-height:0;
        overflow:hidden;
        transition: max-height 0 ease-in;
    }
    &.in {
        max-height:500px;
        transition: max-height 0.35s ease-out;
    }
    
    ul {
        li {
            border-top:1px solid #ddd;
            color:$gray;
            margin:0;
            a {
                color:$gray;
                
                &:hover {
                    background:darken(#fff, 4%);
                }
            }
            &.active a {
                background:transparent;
                color:$brand-primary;
                font-weight:bold;
                &:hover {
                    background:darken(#fff, 4%);
                }
            }
        }
    }
    
    .corporate-customer-toggle-sm {
        padding:20px 0;
        text-align: center;
        .btn {
            color:$gray;
            
            display:inline;
            border:medium none;
            border-right:1px solid #ddd;
            
            &:last-child {
                border-right:medium none;
            }
            &.active {
                background: transparent none repeat scroll 0 0;
                color: #3fae28;
                font-weight: bold;
            }
            
        }
    }
    .btn-mijn-qander {
        margin:5px 0 0 0;
        border-top:1px solid #ddd;
        color: #666666;
        padding: 10px 15px;
        text-align: left;
        background-color:#ececec;
        
        @include isaac-respond-min-width(md){
            &:after {
                background-color: #666666;
                border-radius: 4px;
                content: " ";
                display: inline-block;
                height: 8px;
                margin-left: 10px;
                position: relative;
                top: -1px;
                width: 8px;
            }
        }

        .fa {
            margin-right:10px;
        }
    }
}


#searchbar {
    background:#fff;
    position:absolute;
    width:100%;
    z-index:1;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);

    &.collapse {
        display:block;
        max-height:0;
        overflow:hidden;
        transition: max-height 0 ease-in;
    }
    &.in {
        max-height:100px;
        transition: max-height 0.3s ease-out;
    }

    .form-group {
        margin:10px;

        button {
            background:$brand-secondary;
            color:#fff;
        }
    }
}