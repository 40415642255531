//give buttons in input-group-btn the correct height
.input-group-btn {
  .btn {
    height: $input-height-base;
  }
}

legend {
  border: medium none;
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
}

@include isaac-respond-max-width(sm) {
  .form-horizontal .panel {
    padding: 10px;
  }
}

.form-control {
  //prevent iPhone from zooming in when clicking an input that has a font-size smaller then 16px
  font-size: 16px;

  //additional form control width's
  @include isaac-respond-min-width(sm) {
    &.form-control-25 {
      width: 25%;
    }

    &.form-control-50 {
      width: 50%;
    }

    &.form-control-75 {
      width: 75%;
    }
  }
}

.has-error {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline {
    //Bootstrap makes all these element red if the form-group is in error state
    color: inherit;
  }
  //errors, triggered by javascript validation
  label.has-error {
    @extend .help-block; //inherit margin from help-block
    font-weight: normal;
    color: $state-danger-text;
  }
}
p.has-error {
  color: $state-danger-text;
}

//remove spinner in numeric input fields
input[type="number"] {
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

// remove 'X' in IE
input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

label {
  font-weight: normal;
}

.form-vraag-en-antwoord-zoeken.section-orange {
  padding: 0 0 10px 0;
  @include isaac-respond-min-width(sm) {
    padding: 10px 0 30px 0;
  }
  h3 {
    margin-bottom: 20px;
  }
  form {
    position: relative;
    .form-group {
      width: 100%;
      input {
        border: medium none;
        border-radius: 5px;
        font-size: 18px;
        height: 56px;
        padding: 15px 20px;
        width: 100%;
      }
    }
    button {
      border-radius: 0 5px 5px 0;
      height: 56px;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 56px;
      color: $brand-primary;
      i {
        font-size: 53px;
      }
    }
  }
}
