footer {
    text-align: center;
    @include isaac-respond-min-width(md) {
        text-align: left;
    }

    border-top:1px solid #cccccc;
    background-color:transparent;
    font-size:14px;
    padding-top:30px;
    a {
        color: $gray-base;
        text-decoration: underline;
        &:hover,
        &:focus {
            color: #000;
        }
    }
    ul {
        li {
            margin:20px 0;
            
            @include isaac-respond-min-width(sm) {
                margin:10px 0;
            }
        }
    }

    .footer-nav {
        text-align:center;
        background-color:transparent;
        
        @include isaac-respond-min-width(sm) {
            text-align:left;
        }

        h3 {
            font-size:24px;
            font-weight: normal;
            margin-top:35px;
            color:$brand-secondary;
            
            @include isaac-respond-min-width(sm) {
                margin-top:15px;
            }
            
            a {
                text-decoration: none;
                color:inherit;
            }
        }
    }
  
    .footer-menu {
        padding:20px 0;
        @include isaac-respond-min-width(md) {
            padding-top:40px;
        }
    }
    
    .copyright {
        width:100%;
        border-top:1px solid #ccc;
        padding-top:20px;
        
        @include isaac-respond-min-width(md) {
            padding-top:0;
            border-top:medium none;
            width:auto;
        }
    }
    .links {
        width:100%;
        border-bottom:1px solid #ccc;
        margin-bottom: 20px;
        padding-bottom: 10px;
        
        @include isaac-respond-min-width(md) {
            width:auto;
            margin-left:60px;
            border-bottom:medium none;
            margin-bottom:0;
            padding-bottom: 0;
        }
        li {
            width:100%;
            @include isaac-respond-min-width(sm) {
                border-right: 1px solid $gray;
                padding-right:15px;
                padding-left:12px;
                width: auto;
                
                &:last-child {
                    border-right:medium none;
                }
            }
        }
    }
    .social-media {
        width:100%;
        @include isaac-respond-min-width(md) {
            width:auto;
            margin-top:-7px;
        }
        li {
            a {
                width:33px;
                height:33px;
                line-height: 1;
                padding-top:5px;
                background-color:transparent;
                border-radius:50%;
                text-align:center;
                display:block;
                color:$brand-secondary;
                font-size:28px;

                &:hover,
                &:focus {
                color:$gray-darker;
                }
            }
        }
    }
    .qander {
        width:100%;

        img {
            width: 100px;
            height: 40px;
        }

        @include isaac-respond-min-width(md) {
            width:auto;
            margin-top:0px;
            margin-left:20px;
        }
    }
    .vfn {
        width:100%;
        @include isaac-respond-min-width(md) {
            width:auto;
            margin-top:-7px;
            margin-right:30px;
        }
    }
}



